import './style.css'
import * as THREE from 'three'
// import {VolumetricSpotLightMaterial} from './bower_components/threex.volumetricspotlight/threex.volumetricspotlightmaterial.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { Mesh, OneMinusDstAlphaFactor } from 'three'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js'
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js'
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js'
import { KaleidoShader } from 'three/examples/jsm/shaders/KaleidoShader.js'
import waterVertexShader from './shaders/water/vertex.glsl'
import waterFragmentShader from './shaders/water/fragment.glsl'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import axonVertexShader from './shaders/axon.vert'
import axonFragmentShader from './shaders/axon.frag'




import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js'
import { TechnicolorShader } from 'three/examples/jsm/shaders/TechnicolorShader.js'

import { gsap } from "gsap";
import { generateUUID } from 'three/src/math/MathUtils'

var cursorController = 0;
document.addEventListener("mousedown", (event) => {
    if(cursorController == 0){
    document.body.style.cursor = "grabbing";
    }
})
document.addEventListener("mouseup", (event) => {
    if(cursorController == 0){
    document.body.style.cursor = "grab";
    }
})
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
// console.log(isSafari)




/**
 * Loaders
 */

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)


const cubeTextureLoader = new THREE.CubeTextureLoader()
const textureLoader = new THREE.TextureLoader()
const bevelColorTexture = textureLoader.load('/textures/bevelTexture/MetalCorrugatedIronSheet002_Flat.jpg')
// bevelAlphaTexture= textureLoader.load('MetalCorrugatedIronSheet002_AO_3K_METALNESS.jpg')
const bevelAmbientOcclusionTexture =textureLoader.load('/textures/bevelTexture/MetalCorrugatedIronSheet002_AO_3K_METALNESS.jpg')
const bevelHeightTexture=textureLoader.load('/textures/bevelTexture/MetalCorrugatedIronSheet002_DISP_3K_METALNESS.jpg')
const bevelNormalTexture = textureLoader.load('/textures/bevelTexture/MetalCorrugatedIronSheet002_NRM_3K_METALNESS.jpg')
const bevelMetalnessTexture = textureLoader.load('/textures/bevelTexture/MetalCorrugatedIronSheet002_METALNESS_3K_METALNESS.jpg')
const bevelRoughnessTexture = textureLoader.load('/textures/bevelTexture/MetalCorrugatedIronSheet002_ROUGHNESS_3K_METALNESS.jpg')
// bevelColorTexture.wrapS = THREE.MirroredRepeatWrapping
// bevelColorTexture.wrapT = THREE.MirroredRepeatWrapping
bevelColorTexture.repeat.x = 3
bevelColorTexture.repeat.y = 3
// bevelColorTexture.offset.x = 0.5
// bevelColorTexture.offset.y = 0.5
bevelColorTexture.rotation = Math.PI * 0.25
bevelColorTexture.center.x = 0.5
bevelColorTexture.center.y = 0.5
bevelColorTexture.generateMipmaps = false
bevelColorTexture.minFilter = THREE.NearestFilter
bevelColorTexture.magFilter = THREE.NearestFilter


const matcapTexture = textureLoader.load('/textures/matcaps/3.png')
// const matcapTexture2 = textureLoader.load('/textures/matcaps/5.png')





// Models
let alloModel = null
gltfLoader.load(

    // AlloSphere
    // '/models/alloGEO2.glb',
    // '/models/alloSpeakersTEST.glb',
    // '/models/alloLatestNoSpeakers.glb',
    '/models/alloNoSpeakersNoTrussNoBridge.glb',
    (gltf) =>
    {
        // AlloSphere
        alloModel = gltf.scene
        // console.log(alloModel)
        // Small
        // gltf.scene.scale.set(0.1,0.1,0.1)
        // gltf.scene.position.set(0,-15,0)

        // Medium
        // gltf.scene.scale.set(0.3,0.3,0.3)
        // gltf.scene.position.set(0,-45,0)

        // Large
        // gltf.scene.scale.set(0.35,0.35,0.35)
        // gltf.scene.position.set(0,-50,0)

        // Large with alloSpeakersTest
        // gltf.scene.scale.set(0.35,0.35,0.35)
        gltf.scene.scale.set(0.1, 0.1, 0.1)

        gltf.scene.position.set(0,0,0)


        alloModel.traverse((o) => {
            if (o.isMesh){
                // o.material = material2;
                // o.children[0].material = material2;
                o.material.transparent = true
                // o.material.opacity = 0.05
                o.renderOrder = 2
                // o.material.map = matcapTexture2
                // o.material.depthWrite = false;

                // o.material.needsUpdate = true

            } 
          });
        // Canvas frame
        alloModel.getObjectByName('Icosphere002').traverse((o) => {
            if (o.isMesh){
                // o.material = material2
                
                o.material.depthWrite = false;

                o.material.transparent = true

                // best
                o.material.opacity = 0.05

                // o.material.opacity = 0.02
                // o.material.opacity = 0.1
                // o.material.opacity = 0.3
            }
        })
        // Bevel frame
        alloModel.getObjectByName('Icosphere').traverse((o) => {
            if (o.isMesh){
                // o.material = material2
                o.material.transparent = true


                // speakers switch
                // o.material.opacity = 0.1

                // o.material.map = bevelColorTexture
                // o.material.map = matcapTexture2

                // projector lighting
                // o.material.opacity = 0.06

            }
        })
        // Bevel metal
        alloModel.getObjectByName('Icosphere003').traverse((o) => {
            if (o.isMesh){
                // o.material = material2
                o.material.transparent = true
                o.renderOrder = 1


                // speakers switch
                // o.material.opacity = 0.1

                // o.material.opacity = 1
                o.material.map = bevelColorTexture
                // o.material.map = matcapTexture2

                // projector lighting
                // o.material.opacity = 0.08

            }
        })
        // testRotate1
        // alloModel.rotation.y = Math.PI /2
        scene.add(alloModel)
        // console.log(alloModel.getObjectByName('Icosphere'))
        // console.log(alloModel)
        // gui.add(gltf.scene.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.001).name('rotation')

        // updateAllMaterials()


    }
)
// if(alloModel){
// alloModel.visible = false;
// }

var alloSpeakers = null;
var alloSpeakers2 = null;
var alloTruss = null;
var alloBridge = null;


    gltfLoader.load(
    '/models/alloBridge.glb',
    (gltf) =>
    {
        // gltf.scene.scale.set(0.35,0.35,0.35)
        gltf.scene.scale.set(0.1, 0.1, 0.1)
        gltf.scene.position.set(0,0,0)
        alloBridge = gltf.scene
        alloBridge.traverse((o) => {
            if (o.isMesh){
                o.material.transparent = true
                o.renderOrder = 5
                o.material.opacity = 0.05
                // gsap.to(o.material, {opacity: 1, duration: 6, ease: 'power1.inOut'})
                // o.material.opacity = 0.1
                // o.material = material2
            } 
          });
                // testRotate2
                alloBridge.rotation.y = Math.PI /2
                scene.add(alloBridge)  

        // updateAllMaterials()
    })




/**
 * Base
 */
// Debug
const gui = new dat.GUI()
const debugObject = {}
gui.close()
gui.hide()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
// scene.background = new THREE.Color(0x212121)

const geometry0 = new THREE.BoxGeometry( 1, 1, 1 );
const materialBevel = new THREE.MeshStandardMaterial( {
    roughness: 0,
    // transmission: 1.0,
    transparent: true,
    map: bevelColorTexture,
    aoMap: bevelAmbientOcclusionTexture,
    // displacementMap: bevelHeightTexture,
    // displacementScale: 0.001,
    normalMap: bevelNormalTexture,
    metalnessMap: bevelMetalnessTexture,
    roughnessMap: bevelRoughnessTexture



    // map: doorColorTexture,
    // transparent: true,
    // alphaMap: doorAlphaTexture,
    // aoMap: doorAmbientOcclusionTexture,
    // displacementMap: doorHeightTexture,
    // displacementScale: 0.1,
    // normalMap: doorNormalTexture,
    // metalnessMap: doorMetalnessTexture,
    // roughnessMap: doorRoughnessTexture

} );
const cube = new THREE.Mesh( geometry0, materialBevel );
// scene.add( cube );

// const physicalMaterial = new THREE.MeshPhysicalMaterial({
//     transmission: 1,
//     // thickness: 0.8,
//     // clearcoat: 0.5,
//     // ior: 1.9,
//     // reflectivity: 0.5,
//     roughness: 0

//   });



/**
 * Environment Map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/2/px.jpg',
    '/textures/environmentMaps/2/nx.jpg',
    '/textures/environmentMaps/2/py.jpg',
    '/textures/environmentMaps/2/ny.jpg',
    '/textures/environmentMaps/2/pz.jpg',
    '/textures/environmentMaps/2/nz.jpg'
])
// environmentMap.encoding = THREE.sRGBEncoding
// scene.background = environmentMap
// scene.environment = environmentMap

// debugObject.envMapIntensity = 5
// gui.add(debugObject, 'envMapIntensity').min(0).max(10).step(0.001).onChange(updateAllMaterials)

/**
 * Playground Meshes
 */

// Plane
 const geometry = new THREE.PlaneGeometry( 30, 30 );
 const material = new THREE.MeshStandardMaterial( {color: 0xffffff, side: THREE.DoubleSide} );
 const plane = new THREE.Mesh( geometry, material );
//  scene.add( plane );
 plane.rotation.x = Math.PI/2
plane.receiveShadow = true;
plane.position.y = -2
plane.material.side = THREE.DoubleSide
plane.position.set(0,-15,0)

// Sphere
const geometry2 = new THREE.SphereBufferGeometry( 10, 64, 32 );

//testing normals
const material2 = new THREE.ShaderMaterial({ 
    transparent: true,
    // wireframe: true,
    uniforms:
    {
        uAlpha: { value : 0 },
        iTime: {value: 0}, 
        movePlease: {value: 1.0},
        audio: {value: 1}

    },
    vertexShader: `
    varying vec3 vNormal;
    uniform float movePlease;
    uniform float audio;

    varying vec2 vUv;
    void main()
    {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vUv = uv;
        vNormal = normal;
    }
`,
    fragmentShader: `
    varying vec3 vNormal;
    varying vec2 vUv;
    uniform float iTime;
    uniform float uAlpha;
    uniform float audio;

    #define t iTime
    mat2 m(float a){float c=cos(a), s=sin(a);return mat2(c,-s,s,c);}
    float map(vec3 p){
        p.xz*= m(t*0.4);p.xy*= m(t*0.3);
        vec3 q = p*2.+t;
        return length(p+vec3(sin(t*0.7)))*log(length(p)+1.) + sin(q.x+sin(q.z+sin(q.y)))*0.5 - 1.;
    }
    
    void main(){	
        // vec2 p = vUv- vec2(.9,.5);
        vec3 p = vNormal;
        vec3 cl = vec3(0);
        float d = 2.5;
        for(int i=0; i<=5; i++)	{
            vec3 p = vec3(0.5,0.5,2.5) + normalize(vec3(p -1.0)) * d;
            float rz = map(p);
            float f =  clamp((rz - map(p+.1))*0.9, -.1, 1.);
            vec3 color = vec3(0.1,0.3,.4) + vec3(5., 2.5, 3.)*f;
            cl = cl*color + smoothstep(2.5, .0, rz)*.7*color;
            d += min(rz, 1.);
        }
        gl_FragColor = vec4(cl, uAlpha * audio );
    }
    `
})

const sphere = new THREE.Mesh( geometry2, material2 );
sphere.material.side = THREE.DoubleSide
sphere.flipSided = true;


// scene.add( sphere );
sphere.position.set(0,0,0)
// sphere.castShadow = true;

// Extra Small
const geometry3 = new THREE.SphereBufferGeometry( 13, 128, 128 );

// Small
// const geometry3 = new THREE.SphereBufferGeometry( 12.9, 128, 128 );

// Medium
// const geometry3 = new THREE.SphereBufferGeometry( 38.7, 128, 128 );

// Large
// const geometry3 = new THREE.SphereBufferGeometry( 44.9, 128, 128 );

const sphere2 = new THREE.Mesh( geometry3, material2 );
sphere2.material.side = THREE.DoubleSide
sphere2.flipSided = true;
// scene.add( sphere2 );

sphere2.position.y = 0
sphere2.renderOrder = 1;
// sphere.position.set(0,0,0)
// sphere.castShadow = true;

/**
 * Cursor
 */
//  const cursor = new THREE.Vector3()
//  cursor.x = 0
//  cursor.y = 0
//  cursor.z = 0
//  window.addEventListener('mousemove', (event) => {
//     cursor.x = (event.clientX / sizes.width) -0.5
//     cursor.y = (event.clientY / sizes.height) -0.5
//     //  directionalLight.position.x = cursor.x,
//     //  directionalLight.position.y = cursor.y
//  })
//  console.log(cursor)


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight('#ffffff', 0)
// const directionalLight = new THREE.DirectionalLight('#3189f5', 9)
const directionalLight = new THREE.DirectionalLight('#ffffff', 10)

// const pointLight = new THREE.PointLight(0xffffff, 30, 10, 10)
const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight, 0.2)
// scene.add(directionalLightHelper)

// gui.add(pointLight, 'intensity').min(0).max(30).step(0.001).name('pointLightIntensity')
// gui.add(pointLight, 'distance').min(0).max(30).step(0.001).name('pointLightDistance')
// gui.add(pointLight, 'decay').min(0).max(30).step(0.001).name('pointLightDecay')

// pointLight.position.set(10,0,-10)
// pointLight.castShadow = true;
// pointLight.shadow.camera.far = 15
// pointLight.shadow.mapSize.set(1024, 1024)
// pointLight.shadow.normalBias = 0.02


// directionalLight.position.set(0.25, 3, -2.25)
directionalLight.castShadow = true;
directionalLight.shadow.camera.far = 15
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.normalBias = 0.02
scene.add(directionalLight)
scene.add(ambientLight)

// scene.add(pointLight)

// const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
// scene.add(directionalLightCameraHelper)

gui.addColor(directionalLight, 'color')
// gui.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('directionalLightIntensity')
gui.add(directionalLight, 'intensity').min(0).max(100).step(0.001).name('directionalLightIntensity')

gui.add(ambientLight, 'intensity').min(0).max(10).step(0.001).name('ambientLightIntensity')
// gui.add(directionalLight.position, 'x').min(-15).max(15).step(0.001).name('lightX')
// gui.add(directionalLight.position, 'y').min(-15).max(15).step(0.001).name('lightY')
// gui.add(directionalLight.position, 'z').min(-15).max(15).step(0.001).name('lightZ')


// const pointLightHelper = new THREE.PointLightHelper(pointLight, 0.2)
// scene.add(pointLightHelper)





/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update effect composer
    effectComposer.setSize(sizes.width, sizes.height)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */    
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1500)

//top down
// camera.rotation.y = Math.PI 

// side
// camera.position.set(0, 0, 28)

scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)

controls.addEventListener( 'lock', function () {

	menu.style.display = 'none';

} );

controls.addEventListener( 'unlock', function () {

	menu.style.display = 'block';

} );



// controls.enabled = false
controls.enableDamping = true
controls.dampingFactor = 0.01
controls.zoomSpeed = 0.2
// controls.autoRotate = true
controls.autoRotateSpeed = 1.0
// small
// controls.maxDistance = 35

// large
// controls.maxDistance = 55

// outside
controls.maxDistance = 70

var offset = 0;
var bigOffset = 0;
var mediumOffset = 0;
const createdBy = document.querySelector('#created');
const allobrainText = document.getElementById('allobrain')
const growthText = document.getElementById('growth')
const avText = document.getElementById('av')
const logoYo = document.querySelector('#logo')
const wrapperYo = document.querySelector('.wrapper')
// createdBy.style.opacity = 0
// window.setTimeout(() =>
//         {
//             createdBy.style.opacity = 0.7


//         },4000)


if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){ 
    // controls.enableZoom = false;
    controls.enablePan = false;
    offset = 10;
    bigOffset = 70;
    mediumOffset = 23;
    // createdBy.style.top = 0;
    // createdBy.style.top = 0;
    createdBy.style['padding-right'] = 0;
    createdBy.style['padding-bottom'] = '10px';
    createdBy.style['font-size'] = '9px';
    logoYo.style['padding-bottom'] = '0px';
    createdBy.style['position'] = 'relative';
    allobrainText.style['font-size'] = '1em';
    growthText.style['font-size'] = '1em';
    avText.style['font-size'] = '1em';
    wrapperYo.style['flex-direction'] ='column'



  }
  camera.position.set(0, 28 + offset + offset, 0)



/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    // autoClear: true

    // Best according to postprocessing git
    // powerPreference: "high-performance",
	// antialias: false,
	// stencil: false,
	// depth: false
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.physicallyCorrectLights = true
// renderer.outputEncoding = THREE.sRGBEncoding
// renderer.toneMapping = THREE.ACESFilmicToneMapping
// renderer.toneMappingExposure = 3
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap


gui.add(renderer, 'toneMapping', {
    No: THREE.NoToneMapping,
    Linear: THREE.LinearToneMapping,
    Reinhard: THREE.ReinhardToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping
}).onFinishChange(() => {
    renderer.toneMapping = Number(renderer.toneMapping)
    // updateAllMaterials()
})
gui.add(renderer, 'toneMappingExposure').min(0).max(10).step(0.001)

/**
 * Post processing
 */

// Render target
const renderTarget = new THREE.WebGLRenderTarget(
    800, 
    600,
    {
        samples: renderer.getPixelRatio() === 1 ? 2 : 0
    }
)

// Effect composer
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setSize(sizes.width, sizes.height)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Render pass
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)

// Unreal Bloom pass
const unrealBloomPass = new UnrealBloomPass()
// unrealBloomPass.strength = 1
unrealBloomPass.strength = 0
unrealBloomPass.radius = 0.5
unrealBloomPass.threshold = 0.1
unrealBloomPass.enabled = false;
effectComposer.addPass(unrealBloomPass)

gui.add(unrealBloomPass, 'enabled').name('bloom enabled')
gui.add(unrealBloomPass, 'strength').min(0).max(2).step(0.001).name('bloom strength')
gui.add(unrealBloomPass, 'radius').min(0).max(2).step(0.001).name('bloom radius')
gui.add(unrealBloomPass, 'threshold').min(0).max(1).step(0.001).name('bloom threshold')

// Kaleido shader
const kaleidoShader = new ShaderPass(KaleidoShader)
// kaleidoShader.uniforms.sides.value = 32
kaleidoShader.uniforms.sides.value = 0

effectComposer.addPass(kaleidoShader)
kaleidoShader.enabled = false
gui.add(kaleidoShader, 'enabled').name('kaleido enabled')
gui.add(kaleidoShader.uniforms.sides, 'value').min(0).max(50).step(1).name('kaleido sides')

// RGB shader
const rgbShader = new ShaderPass(RGBShiftShader)
rgbShader.enabled = false
effectComposer.addPass(rgbShader)
gui.add(rgbShader, 'enabled').name('rgb enabled')
gui.add(rgbShader.uniforms.amount, 'value').min(0).max(1).step(0.001).name('RGB amount')
gui.add(rgbShader.uniforms.angle, 'value').min(0).max(5).step(0.001).name('RGB angle')


 
// Technicolor shader
const technicolorShader = new ShaderPass(TechnicolorShader)
technicolorShader.enabled = false
effectComposer.addPass(technicolorShader)


// Horizontal blur shader
const horizontalBlurShader = new ShaderPass(HorizontalBlurShader)
effectComposer.addPass(horizontalBlurShader)
horizontalBlurShader.enabled = false
gui.add(horizontalBlurShader, 'enabled').name('horizontal enabled')
gui.add(horizontalBlurShader.uniforms.h, 'value').min(0).max(0.1).step(0.0001).name('horizontal blur')




// Bokeh pass
let bokehMaxBlur = 0.16614
const bokehPass = new BokehPass( scene, camera, {
    // original
    // focus: 1.0,
    // aperture: 0.025,
    // maxblur: 0.01,

    // tweaked
    // focus: 1.0,
    // aperture: 0.001,
    // maxblur: 0.01,

    // WILD
    focus: 1.0,
    aperture: 3.116,
    // maxblur: 0.16614,
    maxblur: bokehMaxBlur,


    nearClip: camera.near,
    farClip: camera.far,

    width: sizes.width,
    height: sizes.height
} );
effectComposer.addPass(bokehPass)

bokehPass.enabled = false
gui.add(bokehPass, 'enabled').name('bokeh enabled')
gui.add(bokehPass.uniforms.focus, 'value').min(0).max(30).step(0.001).name('bokeh focus')
gui.add(bokehPass.uniforms.aspect, 'value').min(0).max(10).step(0.001).name('bokeh aspect')
gui.add(bokehPass.uniforms.aperture, 'value').min(0).max(10).step(0.001).name('bokeh aperture')
gui.add(bokehPass.uniforms.maxblur, 'value').min(0).max(2).step(0.00001).name('bokeh maxblur')

gui.add(technicolorShader, 'enabled').name('technicolor enabled')




    // Speakers 1
    gltfLoader.load(
        // '/models/alloSpeakersOpto.glb',
        // '/models/alloSpeakersCompressed2.glb',
        // '/models/alloSpeakersCompressed3.glb',
        '/models/alloSpeakersCompressed4.glb',

        // '/models/alloSpeakersCompressedALL.glb',


        (gltf) =>
        {
            // gltf.scene.scale.set(0.35,0.35,0.35)
            gltf.scene.scale.set(0.1, 0.1, 0.1)
            gltf.scene.position.set(0,0,0)
            alloSpeakers = gltf.scene
            alloSpeakers.traverse((o) => {
                if (o.isMesh){
                    o.material.transparent = true
                    o.material.opacity = 0
                    // gsap.to(o.material, {opacity: 1, duration: 6, ease: 'power1.inOut'})
                    // o.material.opacity = 0.1
                    // o.material = material2
                } 
              });
            // scene.add(alloSpeakers)
    
            // updateAllMaterials()
        })

        // Speakers 2
        gltfLoader.load(
            // '/models/alloSpeakersOpto.glb',
            '/models/alloSpeakersCompressed3.glb',
            // '/models/alloSpeakersCompressed4.glb',
    
            (gltf) =>
            {
                // gltf.scene.scale.set(0.35,0.35,0.35)
                gltf.scene.scale.set(0.1, 0.1, 0.1)
                gltf.scene.position.set(1000,0,0)
                alloSpeakers2 = gltf.scene
                alloSpeakers2.traverse((o) => {
                    if (o.isMesh){
                        o.material.transparent = true
                        o.material.opacity = 0
                        // gsap.to(o.material, {opacity: 1, duration: 6, ease: 'power1.inOut'})
                        // o.material.opacity = 0.1
                        // o.material = material2
                    } 
                  });
                scene.add(alloSpeakers2)
        
                // updateAllMaterials()
            })
        
    
        // Truss transition
        gltfLoader.load(
        // '/models/alloTrussOpto.glb',
        '/models/alloTrussCompressed.glb',

        (gltf) =>
        {
            // gltf.scene.scale.set(0.35,0.35,0.35)
            gltf.scene.scale.set(0.1, 0.1, 0.1)
            gltf.scene.position.set(0,0,0)
            alloTruss = gltf.scene
            alloTruss.traverse((o) => {
                if (o.isMesh){
                    o.material.transparent = true
                    o.material.opacity = 0
                    // gsap.to(o.material, {opacity: 1, duration: 5, ease: 'power1.inOut'})
    
                    // o.material.opacity = 0.1
                    // o.material = material2
                } 
              });
            // scene.add(alloTruss)
    
            // updateAllMaterials()
        })


        const exploreButton = document.querySelector('.point');
        const exploreButtonText = document.querySelector('.text');
        const demosButton = document.querySelector('.point2')
        const backButton = document.querySelector('.point3')

        exploreButton.hidden = true;
        demosButton.hidden=true;
        backButton.hidden=true;


window.setTimeout(() =>
        {
            exploreButton.hidden = false;
            demosButton.hidden=false;


        },4000)
window.setTimeout(() =>
        {
            // exploreButton.hidden = false;
            // if(isSafari == false){
            exploreButton.style.opacity = 1
            demosButton.style.opacity = 1;
            // }
            // logo.style.opacity = 1

        },7000)
        // if(sphere2){
        //     exploreButton.style.opacity = 0
        //     // exploreButton.hidden = true;
        // }
// exploreButton.style.opacity = 1

const terrainGeometry = new THREE.PlaneBufferGeometry(800,800, 512, 512)

const heightMap = textureLoader.load('/textures/displacementMaps/sb323.png')
// heightMap.wrapS = THREE.RepeatWrapping;
// heightMap.wrapT = THREE.RepeatWrapping;
heightMap.anisotropy = 32;

const channelText = textureLoader.load('/images/channel3.png')

const channelMaterial = new THREE.MeshBasicMaterial()
channelMaterial.map = channelText
channelMaterial.side = THREE.DoubleSide
channelMaterial.transparent = true
channelMaterial.opacity = 1


const channelGeometry = new THREE.PlaneBufferGeometry(150,150,32,32)
const channelPlane = new THREE.Mesh(channelGeometry, channelMaterial)


channelPlane.rotation.x = -2
channelPlane.rotation.z = Math.PI
channelPlane.rotation.y = 0
// channelPlane.position.set({x: 800, y: 0, z: 800})
channelPlane.position.z = 410
channelPlane.position.y = 2


const ynezText = textureLoader.load('/images/ynez2.png')

const ynezMaterial = new THREE.MeshBasicMaterial()
ynezMaterial.map = ynezText
ynezMaterial.side = THREE.DoubleSide
ynezMaterial.transparent = true;
ynezMaterial.alphaMap = ynezText;
ynezMaterial.opacity = 1




const ynezGeometry = new THREE.PlaneBufferGeometry(150,150,32,32)
const ynezPlane = new THREE.Mesh(ynezGeometry, ynezMaterial) 


ynezPlane.rotation.x = -Math.PI /2
ynezPlane.rotation.z = -Math.PI / 2
ynezPlane.rotation.y = -Math.PI / 2
// channelPlane.position.set({x: 800, y: 0, z: 800})
ynezPlane.position.x = 414
ynezPlane.position.z = -139
ynezPlane.position.y = 70

const pinText = textureLoader.load('/images/locationAndPin3.png')


const pinMaterial = new THREE.MeshBasicMaterial()
pinMaterial.map = pinText
pinMaterial.side = THREE.DoubleSide
pinMaterial.transparent = true;
pinMaterial.alphaMap = pinText
pinMaterial.opacity = 1


const pinGeometry = new THREE.PlaneBufferGeometry(120,120,32,32)
const pinPlane = new THREE.Mesh(pinGeometry, pinMaterial)


// pinPlane.rotation.x = Math.PI / 2
// pinPlane.rotation.z = Math.PI / 2
pinPlane.rotation.y = -Math.PI /2
// pinPlane.position.set({x: 0, y: 0, z: 0})
// pinPlane.position.z = 200
// pinPlane.position.z = 410
// pinPlane.position.y = 60
pinPlane.position.z = 5


// controls panel
const controlsText = textureLoader.load('/images/controls4.png')


const controlsMaterial = new THREE.MeshBasicMaterial()
controlsMaterial.map = controlsText
controlsMaterial.transparent = true;
controlsMaterial.alphaMap = controlsText
controlsMaterial.opacity = 0
controlsMaterial.side = THREE.DoubleSide


const controlsGeometry = new THREE.PlaneBufferGeometry(0.2,0.2,16,16)
const controlsPlane = new THREE.Mesh(controlsGeometry, controlsMaterial)
// controlsPlane.rotation.y = Math.PI/2
// controlsPlane.position.z = 10
// controlsPlane.scale.set(1,1,1)
controlsPlane.position.set(-1.22,-0.8,-1)
// scene.add(controlsPlane)

// const yuriGeometry = new THREE.PlaneBufferGeometry(1,1,16,16)
// yuriPlane.scale.set(35,35,35)
// yuriPlane.position.set(-80,40,-80)
controlsPlane.renderOrder = 2
camera.add(controlsPlane)
gsap.to(controlsMaterial, {opacity: 0.9, duration:7,delay: 2, ease: 'power1.inOut'})



const terrainMaterial = new THREE.ShaderMaterial({ 
    transparent: true,
    side: THREE.DoubleSide,
    wireframe: true,    
    uniforms:
    {
        bumpTexture: { value: heightMap },
        uAlpha: { value : 1 },    
        // Feed the scaling constant for the heightmap
        // bumpScale: { value: 100 }
        bumpScale: { value: 0 },

    },
    vertexShader: `
    // A uniform to contain the heightmap image
    uniform sampler2D bumpTexture;
    // A uniform to contain the scaling constant
    uniform float bumpScale;
    
    // Varyings are variables whose values are decided in the vertext shader
    // But whose values are then needed in the fragment shader
    
    // A variable to store the height of the point
    varying float vAmount;
    // The UV mapping coordinates of a vertex
    varying vec2 vUV;
    
    void main()
    {
        // The "coordinates" in UV mapping representation
        vUV = uv;
    
        // The heightmap data at those coordinates
        vec4 bumpData = texture2D(bumpTexture, uv);
    
        // height map is grayscale, so it doesn't matter if you use r, g, or b.
        vAmount = bumpData.r;
    
        // move the position along the normal
        vec3 newPosition = position + normal * bumpScale * vAmount;
    
        // Compute the position of the vertex using a standard formula
        gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
    }
`,
    fragmentShader: `
    varying vec2 vUV;
    varying float vAmount;
    uniform float uAlpha;
    
    void main()
    {
        // gl_FragColor = vec4(0.0, vAmount, 0.0, 1.0);
        // gl_FragColor = vec4(vAmount, vAmount *0.5, 0.1, 1 );
        gl_FragColor = vec4(vAmount, vAmount, vAmount, uAlpha );

    }
    `
})


const terrain = new THREE.Mesh(terrainGeometry, terrainMaterial);
terrain.position.y = -13
// ambientLight.intensity = 10;
terrain.rotation.x = -Math.PI / 2
// terrain.position.z = 100
// terrain.position.x = -300
terrain.position.z = 200
// terrain.rotation.z = Math.PI / 2
gui.add(terrainMaterial.uniforms.bumpScale, 'value').min(0).max(300).step(1)

// const locationText = textureLoader.load('/images/locationText4.png')
const locationText = textureLoader.load('/images/topo2.png')
const locationMaterial = new THREE.MeshBasicMaterial()
locationMaterial.map = locationText
locationMaterial.side = THREE.DoubleSide
locationMaterial.opacity = 1
locationMaterial.transparent = true;
locationMaterial.alphaMap = locationText


const locationGeometry = new THREE.PlaneBufferGeometry(1,1,16,16)
const locationPlane = new THREE.Mesh(locationGeometry, locationMaterial)
if(offset ==0){
locationPlane.position.set(1115,-550,-1000)
}
else if(offset !=0){
    locationPlane.position.set(250,-600,-1000)
}
locationPlane.scale.set(325,325,325)

const storiesThreeText = textureLoader.load('/images/storiesThree.png')
const storiesThreeMaterial = new THREE.MeshBasicMaterial()
storiesThreeMaterial.map = storiesThreeText
storiesThreeMaterial.side = THREE.DoubleSide
storiesThreeMaterial.transparent = true
storiesThreeMaterial.opacity = 1

const storiesThreeGeometry = new THREE.PlaneBufferGeometry(30,30,32,32)
const storiesThreePlane = new THREE.Mesh(storiesThreeGeometry, storiesThreeMaterial)

const storiesStoriesText = textureLoader.load('/images/storiesStories.png')
const storiesStoriesMaterial = new THREE.MeshBasicMaterial()
storiesStoriesMaterial.map = storiesStoriesText
storiesStoriesMaterial.side = THREE.DoubleSide
storiesStoriesMaterial.transparent = true
storiesStoriesMaterial.opacity = 1

const storiesStoriesGeometry = new THREE.PlaneBufferGeometry(25,25,32,32)
const storiesStoriesPlane = new THREE.Mesh(storiesStoriesGeometry, storiesStoriesMaterial)

const lineMaterial = new THREE.LineBasicMaterial();
const lineMaterial2 = new THREE.LineBasicMaterial();

const linePoints = [];
linePoints.push( new THREE.Vector3( 10, 0, 0 ) );
linePoints.push( new THREE.Vector3( 10, 10, 0 ) );
linePoints.push( new THREE.Vector3( 10, 0, 0 ) );
const linePoints2 = [];
linePoints2.push( new THREE.Vector3( 1, 0, 0 ) );
linePoints2.push( new THREE.Vector3( 0, 0, 0 ) );
linePoints2.push( new THREE.Vector3( -1, 0, 0 ) );

const lineGeometry = new THREE.BufferGeometry().setFromPoints( linePoints );
const lineGeometry2 = new THREE.BufferGeometry().setFromPoints( linePoints2 );
const line = new THREE.Line( lineGeometry, lineMaterial );
const line2 = new THREE.Line(lineGeometry, lineMaterial)
const line3 = new THREE.Line(lineGeometry2, lineMaterial2)
const line4 = new THREE.Line(lineGeometry2, lineMaterial2)
const line5 = new THREE.Line(lineGeometry2, lineMaterial2)
const line6 = new THREE.Line(lineGeometry2, lineMaterial2)




var clickOrder =0;
var rotateDecider = 0;
var directionalLightSwitch =0;
var alloMoon = null;
// gltfLoader.load(
//     '/models/moon/scene.gltf',
//     (gltf) =>
//     {
//         // gltf.scene.scale.set(0.35,0.35,0.35)
//         gltf.scene.scale.set(0.1, 0.1, 0.1)
//         gltf.scene.position.set(0,0,0)
//         alloMoon = gltf.scene
//         // alloMoon.traverse((o) => {
//         //     if (o.isMesh){
//         //         o.material.transparent = true
//         //         o.renderOrder = 5
//         //         o.material.opacity = 0.05
//         //         // gsap.to(o.material, {opacity: 1, duration: 6, ease: 'power1.inOut'})
//         //         // o.material.opacity = 0.1
//         //         // o.material = material2
//         //     } 

//         alloMoon.position.set(300,250,-300)

//     })
var firstDown = 0;
document.addEventListener("mousedown", (event) => {
    if(firstDown == 0){
    sound.play()
    }
    firstDown = 1;
})
const listener = new THREE.AudioListener();
camera.add( listener );
const audioLoader = new THREE.AudioLoader();
const audioLoader2 = new THREE.AudioLoader();
const audioLoader3 = new THREE.AudioLoader();
const audioLoader4 = new THREE.AudioLoader();
const audioLoader5 = new THREE.AudioLoader();
const audioLoader6 = new THREE.AudioLoader();
const audioLoader7 = new THREE.AudioLoader();
const audioLoader8 = new THREE.AudioLoader();
const audioLoader9 = new THREE.AudioLoader();
const audioLoader10 = new THREE.AudioLoader();
const audioLoader11 = new THREE.AudioLoader();
const audioLoader12 = new THREE.AudioLoader();


const sound = new THREE.Audio( listener );
const sound2 = new THREE.Audio(listener)
const sound5 = new THREE.Audio(listener)
const sound6 = new THREE.Audio(listener)
const sound7 = new THREE.Audio(listener)
const sound8 = new THREE.Audio(listener)
const sound9 = new THREE.Audio(listener)
const sound10 = new THREE.Audio(listener)
const sound11 = new THREE.Audio(listener)
const sound12 = new THREE.Audio(listener)



// Positional Audio Speakers
const sound3 = new THREE.PositionalAudio( listener );
// sound3.setRolloffFactor(0.1)
sound3.setRolloffFactor(3)
sound3.setRefDistance(8)
sound3.setDistanceModel("exponential")

// Positional Audio Projectors
const sound4 = new THREE.PositionalAudio( listener );
sound4.setRolloffFactor(0.1)
sound4.setRefDistance(3)




audioLoader.load( "/music/new/main_synth.mp3", function( buffer ) {
    sound.setBuffer( buffer );
    sound.setLoop( true );
    // sound.setVolume( 1 );

    // // const analyser = new THREE.AudioAnalyser( sound10, 32 );
    // const analyser = new THREE.AudioAnalyser( sound, 32 );
    // // get the average frequency of the sound
    // const freqData= [];
// a
});
audioLoader2.load( "/music/new/bass.mp3", function( buffer ) {
    sound2.setBuffer( buffer );
    sound2.setLoop( true );
    sound2.setVolume( 0.7 );
});
audioLoader3.load( "/music/new/positionalGlitch.mp3", function( buffer ) {
    sound3.setBuffer( buffer );
    sound3.setLoop( true );
    sound3.setVolume( 0.3 );
});
audioLoader4.load( "/music/new/projector2.mp3", function( buffer ) {
    sound4.setBuffer( buffer );
    sound4.setLoop( true );
    sound4.setVolume( 0.08 );
});
audioLoader5.load( "/music/new/epic_lead.mp3", function( buffer ) {
    sound5.setBuffer( buffer );
    sound5.setLoop( true );
    // sound.setVolume( 1 );
});
audioLoader6.load( "/music/new/accents.mp3", function( buffer ) {
    sound6.setBuffer( buffer );
    sound6.setLoop( true );
    // sound.setVolume( 1 );
});
audioLoader7.load( "/music/new/khaki_effect.mp3", function( buffer ) {
    sound7.setBuffer( buffer );
    // sound7.setLoop( true );
    sound7.setVolume( 0.25 );
});
audioLoader8.load( "/music/new/helicopter.mp3", function( buffer ) {
    sound8.setBuffer( buffer );
    sound8.setLoop( true );
    // sound.setVolume( 1 );
});
audioLoader9.load( "/music/new/avDemo.mp3", function( buffer ) {
    sound9.setBuffer( buffer );
    sound9.setLoop( true );
    sound9.setVolume( 0 );
});

audioLoader10.load( "/music/new/avDemoKick.mp3", function( buffer ) {
    sound10.setBuffer( buffer );
    sound10.setLoop( true );
    // sound.setVolume( 1 );
});
audioLoader11.load( "/music/new/growthDemo.mp3", function( buffer ) {
    sound11.setBuffer( buffer );
    sound11.setLoop( true );
    sound11.setVolume( 1.2 );
});
audioLoader12.load( "/music/new/demosDowner.mp3", function( buffer ) {
    sound12.setBuffer( buffer );
    // sound11.setLoop( true );
    sound12.setVolume( 0.3 );
});

// const analyser = new THREE.AudioAnalyser( sound10, 32 );
const analyser = new THREE.AudioAnalyser( sound10, 32 );
// get the average frequency of the sound
// const avgFreq = analyser.getAverageFrequency();
// const avgFreq = analyser.getFrequencyData();
// for (let i = 0, len = data.length; i < len; i++) {
//     // access to magnitude of each frequency with data[i].
// }




exploreButtonText.style.width = '150px'

let volumeDecider10 = {x: 1}
let volumeDecider11 = {x: 1}
let volumeDecider12 = {x: 1}
let volumeDecider13 = {x: 0.7}



let volumeDecider = {x: 0.08}
exploreButton.onclick = () => {
    if(clickOrder == 0 ){

        sound.play()
        // SOUND 0
        newPointLight.add(sound3)
        sound3.setVolume(0.01)
        sound3.play()
        sound2.play()

    exploreButton.style.opacity = 0;
    exploreButtonText.disabled= 'disabled';
    demosButton.style.opacity = 0;

    window.setTimeout(() =>
    {
        exploreButtonText.textContent = "TURN ON THE LIGHTS"
        exploreButtonText.style.width = '150px'
        demosButton.hidden=true;
        sound3.setVolume(0.3)


     },3000)

    window.setTimeout(() =>
    {
        exploreButton.style.opacity = 1;
        exploreButtonText.disabled= false;
        exploreButton.disabled= false;
        

     },6000)


    scene.add(newPointLight)
    scene.add(alloSpeakers)
    scene.add(alloTruss)
    alloSpeakers2.position.set(0,0,0)

    alloBridge.traverse((o) => {
        if(o.isMesh){
            gsap.to(o.material, {opacity: 0, duration: 3, ease: 'power1.inOut'})
        }
    })
    alloSpeakers.traverse((o) => {
        if(o.isMesh){
            gsap.to(o.material, {opacity: 1, duration: 6, ease: 'power1.inOut'})
        }
    })
    alloSpeakers2.traverse((o) => {
        if(o.isMesh){
            gsap.to(o.material, {opacity: 1, duration: 6, ease: 'power1.inOut'})
        }
    })


    alloTruss.traverse((o) => {
        if(o.isMesh){
            gsap.to(o.material, {opacity: 1, duration: 5, ease: 'power1.inOut'})
        }
    })


    // og speakers camera
    // gsap.to(camera.position, {x: 0, y: -7, z: 20 + offset, duration: 4, ease: 'power1.inOut'})

    // inside speakers camera
    gsap.to(camera.position, {x:0, y:0,z: 9, duration: 5, ease: 'power1.inOut'})

    alloModel.traverse((o) => {
        if (o.isMesh){
            o.material.transparent = true
            gsap.to(o.material, {opacity: 0.03, duration: 3, ease: 'power1.inOut'})
            // o.material.opacity = 0.1
            // o.material = material2
        } 
      });
    


camera.add(yuriPlane)
gsap.to(yuriMaterial, {opacity: 1, duration: 8, ease: 'power1.inOut'})
gsap.to(newPointLight, {intensity: 1000, duration: 3, ease: 'power2.inOut'})
// gsap.to(ambientLight, {intensity: 1, duration: 8, ease: 'power2.inOut'})
// exploreButton.style.opacity = 0;
    }
    else if(clickOrder ==1){

        // SOUND 1
        scene.add(mouseMesh)
        mouseMesh.add(sound4)

        sound4.play()
        sound3.stop()

        exploreButton.style.opacity = 0;
        exploreButtonText.disabled= 'disabled';

        window.setTimeout(() =>
        {
            exploreButtonText.textContent = "NEXT   ➤"
            exploreButtonText.style.width = '100px'

    
         },3000)
        window.setTimeout(() =>
        {
            exploreButton.style.opacity = 1;
            exploreButtonText.disabled= false;
            exploreButton.disabled= false;
         },6000)
        gsap.to(controlsMaterial, {opacity: 0, duration:3, ease: 'power1.inOut'})

        cursorController = 1;
        document.body.style.cursor = "auto";
        controls.autoRotate = false
        controls.enabled = false;
        gsap.to(yuriMaterial, {opacity: 0, duration: 3, ease: 'power1.inOut'})
        rotateDecider = 1;
        gsap.to(alloModel.rotation, {y: -Math.PI / 2, duration: 6, ease: 'power2.inOut'})
        gsap.to(alloBridge.rotation, {y: -Math.PI / 2, duration: 6, ease: 'power2.inOut'})
        // gsap.to(projectorPlane.position, {z: 30, duration: 8, ease: 'power2.inOut'})
        scene.add(projectorPlane)
        gsap.to(projectorPlane.scale, {x: 1,y: 1, z: 1, duration: 7, ease: 'power1.inOut'})



                window.setTimeout(() =>
                {
                    scene.add(meshVol)
                    scene.add(meshVol2)
                    scene.add(meshVol3)
                    scene.add(meshVol4)
                    scene.add(meshVol5)
                    scene.add(meshVol6)
                    scene.add(meshVol7)
                    scene.add(meshVol8)
                    scene.add(meshVol9)
                    scene.add(meshVol10)
                    scene.add(meshRight1)
                    scene.add(meshRight2)
                    scene.add(meshRight3)
                    scene.add(meshLeft1)
                    scene.add(meshLeft2)
                    scene.add(meshLeft3)

                 },3000)


                gsap.to(camera.position, {x: 0, y: 24, z: -23, duration: 6, ease: 'power1.inOut'})
                gsap.to(ambientLight, {intensity: 10, duration: 6, ease: 'power1.inOut'})

                gsap.to(materialVol.uniforms.attenuation, {value: 13, duration: 10, ease: 'power2.inOut'})

                alloModel.traverse((o) => {
                    if (o.isMesh){
                        o.material.transparent = true
                        gsap.to(o.material, {opacity: 0.08, duration: 3, ease: 'power1.inOut'})
                        // o.material.opacity = 0.1
                        // o.material = material2
                    } 
                  })
                // alloBridge.traverse((o) => {
                //     if(o.isMesh){
                //         gsap.to(o.material, {opacity: 1, duration: 3, ease: 'power1.inOut'})
                //     }
                // })
                alloSpeakers.traverse((o) => {
                    if(o.isMesh){
                        gsap.to(o.material, {opacity: 0, duration: 3, ease: 'power1.inOut'})
                    }
                })
                alloSpeakers2.traverse((o) => {
                    if(o.isMesh){
                        gsap.to(o.material, {opacity: 0, duration: 3, ease: 'power1.inOut'})
                    }
                })
                alloTruss.traverse((o) => {
                    if(o.isMesh){
                        gsap.to(o.material, {opacity: 0, duration: 3, ease: 'power1.inOut'})
                    }
                })
                alloBridge.traverse((o) => {
                    if(o.isMesh){
                        gsap.to(o.material, {opacity: 0.1, duration: 4, ease: 'power1.inOut'})
                    }
                })
                gsap.to(unrealBloomPass, {strength:0.746, duration: 3, ease: 'power1.inOut', delay: 4.5})
                unrealBloomPass.enabled = true;
                // unrealBloomPass.strength = 0.746
                unrealBloomPass.radius = 0.476
                unrealBloomPass.threshold = 0.705

                window.setTimeout(() =>
                {
                    scene.remove(alloSpeakers)
                    scene.remove(newPointLight)
                    scene.remove(alloTruss)
                    scene.remove(alloSpeakers2)
                 },3000)


    }
    else if (clickOrder ==2){
        // sound4.gain = 3
        const updateVolume = () => {
            sound4.setVolume(volumeDecider.x)
        }

        // new TWEEN.Tween(volumeDecider).to({ x: 0}, 1000).onUpdate(function() {sound_b_1.setVolume(this.x);})
        gsap.to(volumeDecider, {x:0, duration: 6, ease: 'power1.inOut', onUpdate: updateVolume})


        sound5.play()
        sound7.play()
        scene.remove(mouseMesh)

        exploreButton.style.opacity = 0;
        exploreButtonText.disabled= 'disabled';

        // window.setTimeout(() =>
        // {
        // sound4.stop()
        //  },2000)



        window.setTimeout(() =>
        {
            exploreButton.style.opacity = 1;
            exploreButtonText.disabled= false;
            exploreButton.disabled= false;
         },9000)
        gsap.to(controlsMaterial, {opacity: 0.9, duration:6,delay: 8, ease: 'power1.inOut'})

        cursorController = 0;
        document.body.style.cursor = "grab";

        scene.add(line)
        scene.add(line2)
        scene.add(line3)
        scene.add(line4)
        scene.add(line5)
        scene.add(line6)
        line3.scale.set(0,0,0)
        line4.scale.set(0,0,0)
        line5.scale.set(0,0,0)
        line6.scale.set(0,0,0)
        line.position.x = 5
        line2.position.x = -25
        line.position.y = -11
        line2.position.y = -11
        line.scale.set(0,0,0)
        line2.scale.set(0,0,0)
        gsap.to(line.scale, {x: 1, y: 2, z: 1, duration: 6,delay: 4, ease: 'power1.inOut'})
        gsap.to(line2.scale, {x: 1, y: 2, z: 1, duration: 6,delay: 4, ease: 'power1.inOut'})
        line3.position.x = 15
        line3.position.y = 9
        line4.position.x = -15
        line4.position.y = 9
        line5.position.x = 15
        line5.position.y = -11
        line6.position.x = -15
        line6.position.y = -11
        gsap.to(line3.scale, {x: 1, y: 1, z: 1, duration: 3,delay: 7, ease: 'power1.inOut'})
        gsap.to(line4.scale, {x: 1, y: 1, z: 1, duration: 3,delay: 7, ease: 'power1.inOut'})
        gsap.to(line5.scale, {x: 1, y: 1, z: 1, duration: 3,delay: 7, ease: 'power1.inOut'})
        gsap.to(line6.scale, {x: 1, y: 1, z: 1, duration: 3,delay: 7, ease: 'power1.inOut'})



        scene.remove(yuriPlane)
        camera.remove(yuriPlane)
        scene.add(storiesThreePlane)
        scene.add(storiesStoriesPlane)
        storiesThreePlane.rotation.y = Math.PI
        storiesStoriesPlane.rotation.y = Math.PI
        storiesThreePlane.position.x = 150
        storiesStoriesPlane.position.x = -150
        storiesThreeMaterial.opacity = 0
        storiesStoriesMaterial.opacity = 0

        gsap.to(storiesThreePlane.position, {x: 50 - mediumOffset,y: 0, z: 0, duration: 8, ease: 'power1.inOut'})
        gsap.to(storiesStoriesPlane.position, {x: -50 + mediumOffset,y: 0, z: 0, duration: 8, ease: 'power1.inOut'})
        gsap.to(storiesThreeMaterial, {opacity: 1, duration: 9, ease: 'power1.inOut'})
        gsap.to(storiesStoriesMaterial, {opacity: 1, duration: 9, ease: 'power1.inOut'})
        

        const deleteProjectors = () => {
            unrealBloomPass.enabled = false
            scene.remove(projectorPlane)
            scene.remove(meshVol)
            scene.remove(meshVol2)
            scene.remove(meshVol3)
            scene.remove(meshVol4)
            scene.remove(meshVol5)
            scene.remove(meshVol6)
            scene.remove(meshVol7)
            scene.remove(meshVol8)
            scene.remove(meshVol9)
            scene.remove(meshVol10)
            scene.remove(meshRight1)
            scene.remove(meshRight2)
            scene.remove(meshRight3)
            scene.remove(meshLeft1)
            scene.remove(meshLeft2)
            scene.remove(meshLeft3)
        }
        gsap.to(projectorPlane.scale, {x: 0,y: 0, z: 0, duration: 2.5, ease: 'power1.inOut'})
        gsap.to(materialVol.uniforms.attenuation, {value: 0, duration: 4, ease: 'power1.inOut'})
        gsap.to(unrealBloomPass, {strength:0, duration: 4, ease: 'power1.inOut',onComplete: deleteProjectors})

        // alloModel.position.set(0,0,0)
        // alloBridge.position.set(0,0,0)
        // camera.position.set(0, 28, 0)

         gsap.to(alloModel.rotation, {y: Math.PI ,  duration: 7.5, ease: 'power1.inOut'})
         gsap.to(alloBridge.rotation, {y: Math.PI ,  duration: 7.5, ease: 'power1.inOut'})
        gsap.to(camera.position, {x: 0, y: -11.86, z: -68.99, duration: 7.5, ease: 'power1.inOut'})

        // alloModel.traverse((o) => {
        //     if(o.isMesh){
        //         gsap.to(o.material, {opacity: 1, duration: 6.5, ease: 'power1.inOut'})
        //     }
        // })
                // Canvas frame
                alloModel.getObjectByName('Icosphere002').traverse((o) => {
                    if (o.isMesh){
                        // o.material = material2
                        o.material.depthWrite = false;
        
                        o.material.transparent = true
        
                        // best
                        o.material.opacity = 0.05
        
                    }
                })
                // Bevel frame
                alloModel.getObjectByName('Icosphere').traverse((o) => {
                    if (o.isMesh){
                        o.material.transparent = true
                        gsap.to(o.material, {opacity: 1, duration: 6.5, ease: 'power1.inOut'})
                    }
                })
                // Bevel metal
                alloModel.getObjectByName('Icosphere003').traverse((o) => {
                    if (o.isMesh){
                        o.material.transparent = true
                        o.renderOrder = 1
                        gsap.to(o.material, {opacity: 1, duration: 6.5, ease: 'power1.inOut'})
                        o.material.map = bevelColorTexture
        
                    }
                })

        controls.enabled = true;
        terrain.position.y = -13
        terrain.position.z = 600
        scene.add(terrain)
        terrain.scale.set(0,0,0)
        gsap.to(terrain.scale, {x: 1, y: 1, z: 1, duration: 5, ease: 'power1.inOut'})
        gsap.to(terrain.position, {z: 200,duration: 7.5, ease: 'power1.inOut'})






    }
    else if (clickOrder ==3){
        sound6.play()
        sound8.play()
        sound5.setLoop( false );

        camera.remove(yuriPlane)
        exploreButton.style.opacity = 0;
        exploreButtonText.disabled= 'disabled';


        window.setTimeout(() =>
        {
            exploreButtonText.textContent = "DEMOS   ➤"
            // exploreButtonText.style.width = '100px'
    
         },3000)
        window.setTimeout(() =>
        {
            exploreButton.style.opacity = 1;
            exploreButtonText.disabled= false;
            exploreButton.disabled= false;
         },11000)
        gsap.to(controlsMaterial, {opacity: 0, duration:3, ease: 'power1.inOut'})

        // directionalLightSwitch = 1;

        
        directionalLight.position.set(0,1,0)
        // scene.add(newPointLight2)
        scene.add(channelPlane)
        channelMaterial.opacity = 0;

        const directional = () => {
            directionalLightSwitch = 1
            directionalLight.position.set(0,1,0)

        }
        const deleteModels = () => {
            scene.remove(alloModel)
            scene.remove(alloBridge)
            scene.remove(storiesStoriesPlane)
            scene.remove(storiesThreePlane)
            scene.remove(line)
            scene.remove(line2)
            scene.remove(line3)
            scene.remove(line4)
            scene.remove(line5)
            scene.remove(line6)
        }
        const showPlane = () => {
            scene.add(pinPlane)
            gsap.to(locationMaterial, {opacity: 1, duration: 4, ease: 'power1.inOut'})
            gsap.to(pinMaterial, {opacity: 0.8, duration: 1,ease: 'power2.inOut'})
        }
        const enableControls = () => {
            controls.autoRotate = true
            controls.autoRotateSpeed = 0.2
            gsap.to(controlsMaterial, {opacity: 0.9, duration:3, ease: 'power1.inOut'})

        }
        gsap.to(line.scale, {x: 0, y: 0, z: 0, duration: 1.5, ease: 'power1.inOut'})
        gsap.to(line2.scale, {x: 0, y: 0, z: 0, duration: 1.5, ease: 'power1.inOut'})
        gsap.to(line3.scale, {x: 0, y: 0, z: 0, duration: 1, ease: 'power1.inOut'})
        gsap.to(line4.scale, {x: 0, y: 0, z: 0, duration: 1, ease: 'power1.inOut'})
        gsap.to(line5.scale, {x: 0, y: 0, z: 0, duration: 1, ease: 'power1.inOut'})
        gsap.to(line6.scale, {x: 0, y: 0, z: 0, duration: 1, ease: 'power1.inOut'})
        gsap.to(line.position, {x: 0, y: 0, z: -200, duration: 8, ease: 'power2.inOut'})
        gsap.to(line2.position, {x: 0, y: 0, z: -200, duration: 8, ease: 'power2.inOut'})
        gsap.to(line3.position, {x: 0, y: 0, z: -200, duration: 8, ease: 'power2.inOut'})
        gsap.to(line4.position, {x: 0, y: 0, z: -200, duration: 8, ease: 'power2.inOut'})
        gsap.to(line5.position, {x: 0, y: 0, z: -200, duration: 8, ease: 'power2.inOut'})
        gsap.to(line6.position, {x: 0, y: 0, z: -200, duration: 8, ease: 'power2.inOut'})

        gsap.to(storiesThreePlane.position, {x: 0,y: 0, z: -200, duration: 8, ease: 'power2.inOut'})
        gsap.to(storiesStoriesPlane.position, {x: 0,y: 0, z: -200, duration: 8, ease: 'power2.inOut'})
        gsap.to(storiesThreeMaterial, {opacity: 0, duration: 2.5, ease: 'power2.inOut'})
        gsap.to(storiesStoriesMaterial, {opacity: 0, duration: 2.5, ease: 'power2.inOut'})

        // directionalLight.intensity = 0;
        // gsap.to(terrainMaterial.uniforms.bumpScale, {value: 100, duration: 6, ease: 'power2.inOut'})
        gsap.to(terrainMaterial.uniforms.bumpScale, {value: 100, duration: 8,  ease: 'power2.inOut'})
        gsap.to(camera.position, {x: -570, y: 200, z: -bigOffset - mediumOffset - offset, duration: 10, ease: 'power2.inOut', onComplete: enableControls})

        gsap.to(directionalLight.position, {x: 0, y: 1, z: 0, duration: 4, ease: 'power2.inOut'})
        // gsap.to(directionalLightSwitch, {value: 1, delay: 1, duration: 4, ease: 'power2.inOut'})

        controls.maxDistance = 1000
        // gsap.to(directionalLight, {intensity: 1000, duration: 6, delay: 2, ease: 'power2.inOut',  onComplete: directional})
        // gsap.to(alloModel.position, {y: 150, duration: 10, ease: 'power2.inOut'})
        gsap.to(terrain.position, {x: 0, z: 0, duration: 8, ease: 'power2.inOut',onComplete: showPlane})
        gsap.to(alloModel.position, {x: 0, z: -200, duration: 8, ease: 'power2.inOut'})
        gsap.to(alloBridge.position, {x: 0, z: -200, duration: 8, ease: 'power2.inOut'})


        gsap.to(channelMaterial, {opacity: 1, duration: 11, delay: 3, ease: 'power2.inOut'})
        // scene.add(alloMoon)
        alloBridge.traverse((o) => {
            if(o.isMesh){
                gsap.to(o.material, {opacity: 0, duration: 4, ease: 'power1.inOut', onComplete: deleteModels})
            }
        })
        alloModel.traverse((o) => {
            if(o.isMesh){
                gsap.to(o.material, {opacity: 0, duration: 4, ease: 'power1.inOut'})
            }
        })
        pinMaterial.opacity = 0


        ynezMaterial.opacity = 0
        scene.add(ynezPlane)
        gsap.to(ynezMaterial, {opacity: 1, duration: 11,delay: 3,ease: 'power2.inOut'})

        camera.add(locationPlane)
        locationMaterial.opacity = 0

    }
    else if (clickOrder == 4){
        sound12.play()

        gsap.to(controlsMaterial, {opacity: 0, duration:3, ease: 'power1.inOut'})

        exploreButton.style.opacity = 0;
        exploreButtonText.disabled= 'disabled';


        window.setTimeout(() =>
        {
            // exploreButton.style.opacity = 1;
            // exploreButtonText.disabled= false;
            // exploreButton.disabled= false;
         },11000)
        const deleteSigns = () => {
            scene.remove(ynezPlane)
            scene.remove(channelPlane)
            scene.remove(pinPlane)

        }
        const deleteOutside = () => {
            controls.autoRotate = false;
            controls.maxDistance = 9.5;
            scene.remove(terrain)
            gsap.to(controlsMaterial, {opacity: 0.9, duration:3, ease: 'power1.inOut'})
            initialDemos()
            if(sound5.isPlaying==true){
                sound5.stop()
            }
            if(sound6.isPlaying==true){
                sound6.stop()
            }
            if(sound8.isPlaying==true){
                sound8.stop()
            }
            if(sound2.isPlaying==true){
                sound2.stop()
            }

            // alloModel.traverse((o) => {
            //     if (o.isMesh){
            //         o.material.transparent = true
            //         o.material = material2
            //     } 
            //   })
            //   gsap.to(material2.uniforms.uAlpha, {value: 1, duration: 6.5, ease: 'power1.inOut'})


        }

        //PLACEHOLDER
        const updateVolume10 = () => {
            sound5.setVolume(volumeDecider10.x)
        }
        gsap.to(volumeDecider10, {x:0, duration: 6, ease: 'power1.inOut', onUpdate: updateVolume10})

        const updateVolume11 = () => {
            sound6.setVolume(volumeDecider11.x)
        }
        gsap.to(volumeDecider11, {x:0, duration: 6, ease: 'power1.inOut', onUpdate: updateVolume11})

        const updateVolume12 = () => {
            sound8.setVolume(volumeDecider12.x)
        }
        gsap.to(volumeDecider12, {x:0, duration: 6, ease: 'power1.inOut', onUpdate: updateVolume12})

        const updateVolume13 = () => {
            sound2.setVolume(volumeDecider13.x)
        }
        gsap.to(volumeDecider13, {x:0, duration: 6, ease: 'power1.inOut', onUpdate: updateVolume13})
    


        gsap.to(ynezMaterial, {opacity: 0, duration: 2.5,ease: 'power1.inOut', onComplete: deleteSigns})
        gsap.to(channelMaterial, {opacity: 0, duration: 2.5, ease: 'power1.inOut'})
        gsap.to(locationMaterial, {opacity: 0, duration: 2.5, ease: 'power1.inOut'})
        gsap.to(pinMaterial, {opacity: 0, duration: 2.5,ease: 'power1.inOut'})
        gsap.to(terrainMaterial.uniforms.bumpScale, {value: -100, duration: 6,  ease: 'power1.inOut', onComplete: deleteOutside})
        gsap.to(terrainMaterial.uniforms.uAlpha, {value: 0, duration: 5,  ease: 'power1.inOut'})


          gsap.to(camera.position, {x: 0, y: 1, z: 9, duration: 6, ease: 'power2.inOut'})
          scene.add(alloModel)
          scene.add(alloBridge)
          alloModel.position.set(0,0,0)
          alloBridge.position.set(0,0,0)

        //   alloBridge.traverse((o) => {
        //     if(o.isMesh){
        //         gsap.to(o.material, {opacity: 0.05, duration: 6, ease: 'power1.inOut'})
        //     }
        // })
        // alloModel.traverse((o) => {
        //     if (o.isMesh){
        //         o.material.transparent = true
        //         o.material = material2
        //     } 
        //   })
        //   gsap.to(material2.uniforms.uAlpha, {value: 1, duration: 9,delay: 3, ease: 'power1.inOut'})
                        // Canvas frame
                        alloModel.getObjectByName('Icosphere002').traverse((o) => {
                            if (o.isMesh){
                                // o.material = material2
                                o.material.depthWrite = false;
                
                                o.material.transparent = true
                
                                // best
                                o.material.opacity = 0.05
                
                            }
                        })
                        // Bevel frame
                        alloModel.getObjectByName('Icosphere').traverse((o) => {
                            if (o.isMesh){
                                o.material.transparent = true
                                gsap.to(o.material, {opacity: 1, duration: 6.5, ease: 'power1.inOut'})
                            }
                        })
                        // Bevel metal
                        alloModel.getObjectByName('Icosphere003').traverse((o) => {
                            if (o.isMesh){
                                o.material.transparent = true
                                o.renderOrder = 1
                                gsap.to(o.material, {opacity: 1, duration: 6.5, ease: 'power1.inOut'})
                                o.material.map = bevelColorTexture
                
                            }
                        })
        rotateDecider=0

    }
    else if (clickOrder ==5){
        gsap.to(controlsMaterial, {opacity: 0, duration:3, ease: 'power1.inOut'})
        exploreButtonText.disabled= 'disabled';


        exploreButton.style.opacity = 0
        window.setTimeout(() =>
        {
            exploreButton.hidden = true;
         },5000)

        kaleidoShader.uniforms.sides.value = 0.5;
        bokehPass.uniforms.maxblur.value = 0;
        bokehPass.uniforms.aperture.value = 0.04;
        bokehPass.uniforms.focus.value = 5.0;
        kaleidoShader.enabled = true;
        bokehPass.enabled = true;
        gsap.to(kaleidoShader.uniforms.sides, {value: 32, duration: 40, ease: 'power2.inOut'})
        gsap.to(bokehPass.uniforms.maxblur, {value: 0.25, duration: 40, ease: 'power2.inOut'})




    }
    
    clickOrder +=1
}

//end onclick


const speakersText = textureLoader.load('/images/speakersText.png')
// let yuriMaterial = new THREE.ShaderMaterial(
//     {
//         side: THREE.DoubleSide,
//         uniforms: {
//             time: {value: 0},
//             image: {value: new THREE.TextureLoader.load()},
//             resolution: {value: new THREE.Vector4()},
//             uvRate1: {value: new THREE.Vector2(1,1)}
//         },
//         vertexShader: vertex,
//         fragmentShader: fragment
//     }
// );
const yuriMaterial = new THREE.MeshBasicMaterial()
yuriMaterial.map = speakersText
yuriMaterial.side = THREE.DoubleSide
yuriMaterial.transparent = true
yuriMaterial.opacity = 0


const yuriGeometry = new THREE.PlaneBufferGeometry(1,1,16,16)
const yuriPlane = new THREE.Mesh(yuriGeometry, yuriMaterial)

yuriPlane.scale.set(35,35,35)


// yuriPlane.position.set(40,0,-40)

// yuriPlane.rotation.x = Math.PI/4


yuriPlane.position.set(-80 + bigOffset,40 + offset,-80)

// const testButton = document.querySelector('.div-1');
// testButton.onclick = () => {
//     // console.log("TEST CLICKED")
//     scene.remove(alloSpeakers)
//     scene.remove(alloSpeakers2)
//     scene.remove(newPointLight)
// }

const newPointLight = new THREE.PointLight(0xffffff, 0, 1000, 2);
const newPointLight2 = new THREE.PointLight(0xffffff, 0, 1000, 20);
// scene.add(newPointLight)
const newPointLightHelper = new THREE.PointLightHelper(newPointLight, 1 )
// scene.add(newPointLightHelper)

const testMaterial = new THREE.MeshStandardMaterial()
testMaterial.side= THREE.DoubleSide
testMaterial.transparent = true
testMaterial.alphaTest = 0.5
// var alphaMap = new THREE.TextureLoader().load('/textures/alphaMaps/alphaMap1.png');
// testMaterial.alphaMap = alphaMap;
// testMaterial.alphaMap.magFilter = THREE.NearestFilter;
// testMaterial.alphaMap.wrapT = THREE.RepeatWrapping;
// testMaterial.alphaMap.repeat.y = 1;
// const testGeometry = new THREE.BoxGeometry(5, 5, 5 )
// const testGeometry = new THREE.CylinderGeometry(2, 0, 10, 58,5  )
const testGeometry = new THREE.CylinderGeometry(1.5, 0, 12, 40,40  )
// const testGeometry2 = new THREE.CylinderGeometry(2, 0, 15, 40,40  )


// var geometryVol = new THREE.CylinderGeometry(0, 1.5, 10, 32*2, 20, true)
var cylinderHeight = testGeometry.parameters.height
// testGeometry.applyMatrix( new THREE.Matrix4().makeTranslation( 0, cylinderHeight/2, 0 ) );
testGeometry.translate( 0, cylinderHeight/2, 0 )
testGeometry.applyMatrix4( new THREE.Matrix4().makeRotationX( Math.PI /2 ) );
const testBox = new THREE.Mesh(testGeometry, testMaterial)
// scene.add(testBox)

// const boxTestGeometry = new THREE.BoxBufferGeometry(5,5,5,5)
// const boxTestMesh = new THREE.Mesh(boxTestGeometry, material2)
// scene.add(boxTestMesh)


const threex= function(){
	// 
	var vertexShader	= [
		'varying vec3 vNormal;',
		'varying vec3 vWorldPosition;',
		
		'void main(){',
			'// compute intensity',
			'vNormal		= normalize( normalMatrix * normal );',

			'vec4 worldPosition	= modelMatrix * vec4( position, 1.0 );',
			'vWorldPosition		= worldPosition.xyz;',

			'// set gl_Position',
			'gl_Position	= projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
		'}',
	].join('\n')
	var fragmentShader	= [
		'varying vec3		vNormal;',
		'varying vec3		vWorldPosition;',

		'uniform vec3		lightColor;',

		'uniform vec3		spotPosition;',

		'uniform float		attenuation;',
		'uniform float		anglePower;',

		'void main(){',
			'float intensity;',

			//////////////////////////////////////////////////////////
			// distance attenuation					//
			//////////////////////////////////////////////////////////
			'intensity	= distance(vWorldPosition, spotPosition)/attenuation;',
			'intensity	= 1.0 - clamp(intensity, 0.0, 1.0);',

			//////////////////////////////////////////////////////////
			// intensity on angle					//
			//////////////////////////////////////////////////////////
			'vec3 normal	= vec3(vNormal.x, vNormal.y, abs(vNormal.z));',
			'float angleIntensity	= pow( dot(normal, vec3(0.0, 0.0, 1.0)), anglePower );',
			'intensity	= intensity * angleIntensity;',		
			// 'gl_FragColor	= vec4( lightColor, intensity );',

			//////////////////////////////////////////////////////////
			// final color						//
			//////////////////////////////////////////////////////////

			// set the final color
			'gl_FragColor	= vec4( lightColor, intensity);',
		'}',
	].join('\n')

	// create custom material from the shader code above
	//   that is within specially labeled script tags
	var material	= new THREE.ShaderMaterial({
		uniforms: { 
			attenuation	: {
				type	: "f",
				// value	: 5.0
                // value	: 14.5
                // value: 13
                value: 0 
			},
			anglePower	: {
				type	: "f",
				value	: 0.5
			},
			spotPosition		: {
				type	: "v3",
				value	: new THREE.Vector3( 0, 0, 0 )
			},
			lightColor	: {
				type	: "c",
				value	: new THREE.Color('cyan')
			},
		},
		vertexShader	: vertexShader,
		fragmentShader	: fragmentShader,
		// side		: THREE.DoubleSide,
		// blending	: THREE.AdditiveBlending,
		transparent	: true,
		depthWrite	: false,
	});
	return material
}


// var geometryVol = new THREE.CylinderGeometry(0, 1.5, 5, 32*2, 20, true)
// var geometryVol = new THREE.CylinderGeometry(0, 1.5, 10, 32*2, 20, true)
var geometryVol = new THREE.CylinderGeometry(0, 1.5, 10, 32*2, 20, true)
// var geometryVol2 = new THREE.CylinderGeometry(0, 1.5, 10, 32*2, 20, true)

// const testGeometry = new THREE.CylinderGeometry(2, 0, 10, 58,5  )

// var geometryVol = new THREE.CylinderGeometry(0, 1.5, 10, 32*2, 20, true)

var cylinderHeightVol = geometryVol.parameters.height
// geometryVol.translate( 0, cylinderHeightVol/2, 0 )
// geometryVol.applyMatrix4( new THREE.Matrix4().makeRotationX( Math.PI / 2 ) );


var materialVol = threex()
// materialVol.transparent = true;
// materialVol.opacity = 0;
var meshVol = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol)
var meshVol2 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol2)
var meshVol3 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol3)
var meshVol4 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol4)
var meshVol5 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol5)
var meshVol6 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol6)
var meshVol7 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol7)
var meshVol8 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol8)
var meshVol9 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol9)
var meshVol10 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshVol10)
var meshRight1 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshRight1)
var meshRight2 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshRight2)
var meshRight3 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshRight3)
var meshLeft1 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshLeft1)
var meshLeft2 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshLeft2)
var meshLeft3 = new THREE.Mesh(testGeometry, materialVol)
// scene.add(meshLeft3)


// Original
// meshVol2.position.set(0.5,2,-10)
// meshVol3.position.set(1.5,2,-10)
// meshVol4.position.set(-1.5,2,-10)
// meshVol5.position.set(-0.5,2,-10)
// meshVol6.position.set(-0.5,2,10)
// meshVol7.position.set(0.5,2,10)
// meshVol8.position.set(-1.5,2,10)
// meshVol9.position.set(1.5,2,10)
// meshVol.position.set(0,-5,2)
// meshVol10.position.set(0,-5,-2)

// New Rotated
meshVol2.position.set(-10,2,0.5)
meshVol3.position.set(-10,2,1.5)
meshVol4.position.set(-10,2,-1.5)
meshVol5.position.set(-10,2,-0.5)
meshVol6.position.set(10,2,-0.5)
meshVol7.position.set(10,2,0.5)
meshVol8.position.set(10,2,-1.5)
meshVol9.position.set(10,2,1.5)
meshVol.position.set(2,-5,0)
meshVol10.position.set(-2,-5,0)
meshRight1.position.set(-10,-5,0)
meshRight2.position.set(-9,-5,0)
meshRight3.position.set(-9.5,-5,-1)
meshLeft1.position.set(10,-5,0)
meshLeft2.position.set(9,-5,0)
meshLeft3.position.set(9.5,-5,-1)

// gui.add(meshVol.position, 'x').min(-60).max(60).step(0.001)
// gui.add(meshVol.position, 'y').min(-60).max(60).step(0.001)
// gui.add(meshVol.position, 'z').min(-60).max(60).step(0.001)
var meshVolLookAt = new THREE.Vector3()
// gui.add(meshVolLookAt, 'x').min(-60).max(60).step(0.001)
// gui.add(meshVolLookAt, 'y').min(-60).max(60).step(0.001)
// gui.add(meshVolLookAt, 'z').min(-60).max(60).step(0.001)
materialVol.uniforms.lightColor.value.set('white')
materialVol.uniforms.spotPosition.value	= meshVol.position
var raycaster = new THREE.Raycaster();
var mouse = new THREE.Vector2();
var pointOfIntersection = new THREE.Vector3();

var mouseGeometry = new THREE.SphereGeometry(0.01, 0, 0);
var mouseMaterial = new THREE.MeshBasicMaterial({
    color: 0x0000ff,
    transparent: true,
    opacity: 0
});
var mouseMesh = new THREE.Mesh(mouseGeometry, mouseMaterial);
mouseMesh.position.z = -5;
// scene.add(mouseMesh);

function onMouseMove(event){
    var planeLook = new THREE.Plane(new THREE.Vector3(0, 0, 1), -10);

        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
      mouse.y = -( event.clientY / window.innerHeight ) * 2 + 1;

      var vectorYo = new THREE.Vector3(mouse.x, mouse.y, 0.5);
      vectorYo.unproject( camera );
      var dir = vectorYo.sub( camera.position ).normalize();
      var distanceYo = - camera.position.z / dir.z;
      var pos = camera.position.clone().add( dir.multiplyScalar( distanceYo ) );
      mouseMesh.position.copy(pos);

      raycaster.setFromCamera(mouse, camera);
        raycaster.ray.intersectPlane(planeLook, pointOfIntersection);
        meshVol.lookAt(pointOfIntersection)
        meshVol2.lookAt(pointOfIntersection)
        meshVol3.lookAt(pointOfIntersection)
        meshVol4.lookAt(pointOfIntersection)
        meshVol5.lookAt(pointOfIntersection)
        meshVol6.lookAt(pointOfIntersection)
        meshVol7.lookAt(pointOfIntersection)
        meshVol8.lookAt(pointOfIntersection)
        meshVol9.lookAt(pointOfIntersection)
        meshVol10.lookAt(pointOfIntersection)
        meshRight1.lookAt(pointOfIntersection)
        meshRight2.lookAt(pointOfIntersection)
        meshRight3.lookAt(pointOfIntersection)
        meshLeft1.lookAt(pointOfIntersection)
        meshLeft2.lookAt(pointOfIntersection)
        meshLeft3.lookAt(pointOfIntersection)
      var intersects = raycaster.intersectObject(projectorPlane)
      if(intersects.length>0){
          intersects[0].point.x *=-1
          projectorMaterial.uniforms.mouse.value = intersects[0].point;
        //   console.log(intersects[0].point.x)
      }
}
canvas.addEventListener("mousemove", onMouseMove, false);


  const projectorsText = textureLoader.load('/images/projectorsText.png')
//   const projectorsText = textureLoader.load('/images/fox.jpeg')

  const projectorsTextAlpha = textureLoader.load('/images/projectorsTextAlpha.png')

// const projectorMaterial = new THREE.MeshStandardMaterial()
// projectorMaterial.map = projectorsText
// yuriMaterial.alphaMap = projectorsTextAlpha
// projectorMaterial.side = THREE.DoubleSide
// projectorMaterial.transparent = true
// projectorMaterial.opacity = 1

const projectorMaterial = new THREE.ShaderMaterial({
    side: THREE.DoubleSide,
    uniforms: {
        time: {value: null},
        text: {value: projectorsText},
        resolution: {value: new THREE.Vector4()},
        mouse: {value: new THREE.Vector3()},
        progress: { value: 0}
    },
    vertexShader: `
    uniform float time;
    varying vec2 vUv;
    varying vec3 vPosition;

    void main()
    {
        vUv = uv;
        vPosition = position;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

    }
`,
    fragmentShader: `
    uniform float time;
    uniform float progress;
    uniform sampler2D text;
    uniform sampler2D texture2;
    uniform vec4 resolution;


    varying vec2 vUv;
    uniform vec3 mouse;
    varying vec3 vPosition;

    float map(float value, float min1, float max1, float min2, float max2){
        return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
    }
    
    void main(){	

        
        vec2 direction = normalize(vPosition.xy - mouse.xy);
        float dist = length(vPosition.xy - mouse.xy);

        float prox = 1. - map(dist,0., 8.0, 0., 1.0);

        prox = clamp(prox, 0.,1.);

        vec2 zoomedUV = vUv + direction * prox * progress;

        vec2 zoomedUV1 = mix(vUv, mouse.xy + vec2(0.9), prox * progress);
        vec4 textColor = texture2D(text, zoomedUV1);


        gl_FragColor = textColor;
        // gl_FragColor = vec4(dist,dist,dist,1.);
        // gl_FragColor = vec4(direction, 0., 1.);

    }
    `
})

  controls.enablePan = false;

const projectorMaterial2 = new THREE.MeshBasicMaterial({color: 'white', side: THREE.DoubleSide})


const projectorGeometry = new THREE.PlaneGeometry(35,35,1,1)
const projectorPlane = new THREE.Mesh(projectorGeometry, projectorMaterial)

const projectorPlane2 = new THREE.Mesh(projectorGeometry, projectorMaterial2)

// projectorPlane.scale.set(35,35,35)
projectorPlane.rotation.y = Math.PI 
projectorPlane.position.set(0,2,30)
// projectorPlane.position.set(0,2,100)
projectorPlane.scale.set(0,0,0)


projectorPlane2.scale.set(35,35,35)
projectorPlane2.position.set(0,0,20)

// scene.add(projectorPlane)
// scene.add(projectorPlane2)
var settings = {progress: 0}
settings.progress = 0.009;
gui.add(settings, "progress", 0, 1, 0.01)

const safariText = textureLoader.load('/images/safari.png')


const safariMaterial = new THREE.MeshBasicMaterial()
safariMaterial.map = safariText
// controlsMaterial.transparent = true;
// controlsMaterial.alphaMap = controlsText
safariMaterial.opacity = 1
controlsMaterial.side = THREE.DoubleSide

const safariGeometry = new THREE.PlaneBufferGeometry(1,1,16,16)
const safariPlane = new THREE.Mesh(safariGeometry, safariMaterial)
// controlsPlane.rotation.y = Math.PI/2
// controlsPlane.position.z = 10
// controlsPlane.scale.set(1,1,1)
safariPlane.position.set(0,0,-0.75)
// scene.add(controlsPlane)

// const yuriGeometry = new THREE.PlaneBufferGeometry(1,1,16,16)
// yuriPlane.scale.set(35,35,35)
// yuriPlane.position.set(-80,40,-80)

var totalScene =0;
let volumeDecider2 = {x: 0}
var audioDecider = 0;
const avButton = document.getElementById("av");
avButton.onclick = () => {
    audioDecider = 1;
    volumeDecider.x = 1;

    const updateVolume2 = () => {
        sound.setVolume(volumeDecider.x)
    }
    gsap.to(volumeDecider, {x:0, duration: 4, ease: 'power1.inOut', onUpdate: updateVolume2})

    const updateVolume3 = () => {
        sound9.setVolume(volumeDecider2.x)
    }
    gsap.to(volumeDecider2, {x:1, duration: 4, ease: 'power1.inOut', onUpdate: updateVolume3})

    sound9.play()
    sound10.play()
    growthActivation = 1

    allobrainText.style.animation = "fadeOut4 2s"
    growthText.style.animation = "fadeOut4 2s"
    avText.style.animation = "fadeOut4 2s"
    window.setTimeout(() =>
    {
    allobrainText.hidden=true
    growthText.hidden=true
    avText.hidden=true





    },2000)

    window.setTimeout(() =>
    {
        gsap.to(material2.uniforms.uAlpha, {value: 1, duration: 6.5,ease: 'power1.inOut'})
    // Canvas frame
    alloModel.getObjectByName('Icosphere002').traverse((o) => {
        if (o.isMesh){
            o.material = material2
            o.material.depthWrite = false;

            o.material.transparent = true

            // best
            // o.material.opacity = 0.05
            gsap.to(o.material, {opacity: 0.05, duration: 6.5, ease: 'power1.inOut'})
            scene.remove(sphere2)

        }
    })
        // Bevel frame
        alloModel.getObjectByName('Icosphere').traverse((o) => {
            if (o.isMesh){
                o.material.transparent = true
                gsap.to(o.material, {opacity: 1, duration: 6.5, ease: 'power1.inOut'})
            }
        })
        // Bevel metal
        alloModel.getObjectByName('Icosphere003').traverse((o) => {
            if (o.isMesh){
                o.material.transparent = true
                o.renderOrder = 1
                gsap.to(o.material, {opacity: 1, duration: 6.5, ease: 'power1.inOut'})
                o.material.map = bevelColorTexture
    
            }
        })
    },5000)


    homeButton.hidden = true;
    window.setTimeout(() =>
        {

            backButton.hidden=false;
            scene.remove(alloBridge)
        },8000)
        gsap.to(material2.uniforms.uAlpha, {value: 0, duration: 5,ease: 'power1.inOut'})


        gsap.to(controlsMaterial, {opacity: 0, duration:3, ease: 'power1.inOut'})
        kaleidoShader.uniforms.sides.value = 0.5;
        bokehPass.uniforms.maxblur.value = 0;
        // bokehPass.uniforms.aperture.value = 0.04;
        // bokehPass.uniforms.aperture.value = 1;
        bokehPass.uniforms.aperture.value = 3.116
        bokehPass.uniforms.aspect.value = 1.7262737262737262
        bokehPass.uniforms.focus.value = 5.0;
        kaleidoShader.enabled = true;
        bokehPass.enabled = true;
        gsap.to(kaleidoShader.uniforms.sides, {value: 32, duration: 40, ease: 'power2.inOut'})
        gsap.to(bokehPass.uniforms.maxblur, {value: 0.25, duration: 40, ease: 'power2.inOut'})

}
// }





var brain2;
const allobrainButton = document.getElementById("allobrain");
allobrainButton.onclick = () => {
    // growthActivation = 1

    // console.log(ambientLight.intensity)
    // console.log(newPointLight.intensity)
    // console.log(directionalLight.intensity)
    ambientLight.intensity =0
    newPointLight.intensity = 0;
    allobrainText.style.animation = "fadeOut4 2s"
    growthText.style.animation = "fadeOut4 2s"
    avText.style.animation = "fadeOut4 2s"
    window.setTimeout(() =>
    {
    allobrainText.hidden=true
    growthText.hidden=true
    avText.hidden=true
    },2000)



    homeButton.hidden = true;
    window.setTimeout(() =>
        {
            backButton.hidden=false;
        },8000)
    controls.maxDistance = 100
//     if(totalScene == 0){
//     for( var i = scene.children.length - 1; i >= 0; i--) { 
//         theObj = scene.children[i];
//         scene.remove(theObj); 
//         if(scene.children.length == 0){
//             totalScene=1;
//         }
//    }
// }

// if(totalScene ==1){
    gsap.to(camera.position, {x: 90, y: 0, z: 0, duration: 6, ease: 'power2.inOut'})

gltfLoader.load(
    '/models/brain2/scene.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(50, 50, 50)
        gltf.scene.position.set(0,-30,0)
        brain2 = gltf.scene

        scene.remove(alloModel)
        scene.remove(alloBridge)
        scene.remove(sphere2)
        // brain2.traverse((o) => {
        //     if (o.isMesh){
        //         o.material.opacity = 0;
        //         gsap.to(o.material, {opacity: 0.5, duration: 6, ease: 'power2.inOut'})

        //     } 
        //   });
        scene.add(brain2)


    })
}
// }

const groupYo = new THREE.Group();
scene.add(groupYo);

/* Store each particle coordinates & color */
const vertices = [];
const colors = [];
/* The geometry of the points */
const sparklesGeometry = new THREE.BufferGeometry();
/* The material of the points */
const sparklesMaterial = new THREE.PointsMaterial({
  size: 0.01,
  alphaTest: 0.2,
  map: new THREE.TextureLoader().load("/textures/particles/1.png"),
  vertexColors: true // Let Three.js knows that each point has a different color
});
/* Create a Points object */
const points = new THREE.Points(sparklesGeometry, sparklesMaterial);
/* Add the points into the scene */
// groupYo.add(points);

const growthButton = document.getElementById("growth");

let path = null;
    let sampler = null;
var brainObj;
var theObj
var growthActivation = 0;
var volumeDecider3 = {x:0};
growthButton.onclick = () => {

    volumeDecider.x = 1;

    const updateVolume2 = () => {
        sound.setVolume(volumeDecider.x)
    }
    gsap.to(volumeDecider, {x:0, duration: 2, ease: 'power1.inOut', onUpdate: updateVolume2})

    const updateVolume3 = () => {
        sound11.setVolume(volumeDecider3.x)
    }
    gsap.to(volumeDecider3, {x:1.2, duration: 2, ease: 'power1.inOut', onUpdate: updateVolume3})
    sound11.play()

    // gsap.to(material2.uniforms.uAlpha, {value: 0, duration: 1,ease: 'power1.inOut'})
    // alloBridge.traverse((o) => {
    //     if(o.isMesh){
    //         gsap.to(o.material, {opacity: 0, duration: 1, ease: 'power1.inOut'})
    //     }
    // })
    // alloModel.traverse((o) => {
    //     if(o.isMesh){
    //         gsap.to(o.material, {opacity: 0, duration: 1, ease: 'power1.inOut'})
    //     }
    // })
    allobrainText.style.animation = "fadeOut4 2s"
    growthText.style.animation = "fadeOut4 2s"
    avText.style.animation = "fadeOut4 2s"
    window.setTimeout(() =>
    {
    allobrainText.hidden=true
    growthText.hidden=true
    avText.hidden=true
    },2000)



    homeButton.hidden = true;
    window.setTimeout(() =>
        {
            backButton.hidden=false;
        },8000)


    growthActivation = 1;
    exploreButton.style.opacity = 0
    demosButton.style.opacity = 0
    if(totalScene == 0){
    for( var i = scene.children.length - 1; i >= 0; i--) { 
        theObj = scene.children[i];
        scene.remove(theObj); 
        if(scene.children.length == 0){
            totalScene=1;
        }
   }
}
    if(totalScene ==1){
        scene.add(groupYo)
        camera.add(controlsPlane)
    // controls.enabled = false;
    controls.enableZoom =false
    // controlsPlane.opacity = 0;
    // camera.remove(controlsPlane)
    // if(kaleidoDecider ==1){
        camera.add(controlsPlane)




    // }
    // kaleidoShader.uniforms.sides.value = 0.5;
    // kaleidoShader.enabled = true;
    // gsap.to(kaleidoShader.uniforms.sides, {value: 1, duration: 20, ease: 'power1.inOut'})
    bokehPass.enabled=true
    bokehPass.uniforms.maxblur.value = 0.16614 
    bokehPass.uniforms.aperture.value = 0.00001
    bokehPass.uniforms.aspect.value = 0
    bokehPass.uniforms.focus.value = 30

    controls.maxDistance = 1000
    camera.position.set(29, -15,310 + mediumOffset + mediumOffset)

new OBJLoader().load(
    // '/models/brainObj/brainObj.obj',
    // '/models/-sketch/neuron2.obj',
    // '/models/singularity.obj',
    // '/models/heads.obj',
    // '/models/human.obj',
    // '/models/face2.obj',
        // '/models/snake.obj',
        // '/models/tree1.obj',
        // '/models/gravity.obj',
        // '/models/gravity_low1.obj',
        // '/models/gravity_low2.obj',
        '/models/gravity_low3.obj',





    (obj) => {
        // scene.add(obj.children[0])
        // console.log(obj)
      /* The loaded object with my file being a group, I need to pick its first child */
      brainObj = obj.children[0];
    //   brainObj.geometry.scale( 100, 100, 100 );

    // OGGG
      brainObj.geometry.scale( 3, 3, 3);

        //   brainObj.geometry.scale( 6, 6, 6);



      scene.remove(alloModel)
      scene.remove(alloBridge)

    
      /* Create a surface sampler from the loaded model */
      sampler = new MeshSurfaceSampler(brainObj).build();   
        path = new Path();
        groupYo.add(path.line);   
        // groupYo.position.set(-100,-50,250)  
        // groupYo.rotation.y = Math.PI /2
     }
     
  );
    }
    }


/* Vector to sample a random point */
const tempPosition = new THREE.Vector3();
class Path {
  constructor () {
    if(brainObj){

    
    /* The array with all the vertices of the line */
    this.vertices = [];
    /* The geometry of the line */
    this.geometry = new THREE.BufferGeometry();
    /* The material of the line */
    this.material = new THREE.LineBasicMaterial({color: 0x14b1ff, transparent: true, opacity: 0.5});
    /* The Line object combining the geometry & the material */
    this.line = new THREE.Line(this.geometry, this.material);
    
    /* Sample the first point of the line */
    sampler.sample(tempPosition);
    this.previousPoint = tempPosition.clone();
    }
  }
  update () {
    if(brainObj){

    
    /* Variable used to exit the while loop when we find a point */
    let pointFound = false;
    /* Loop while we haven't found a point */
    while (!pointFound) {
      /* Sample a random point */
      sampler.sample(tempPosition);
      /* If the new point is less 30 units from the previous point */
      if (tempPosition.distanceTo(this.previousPoint) < 30) {
        /* Add the new point in the vertices array */
        this.vertices.push(tempPosition.x, tempPosition.y, tempPosition.z);
        /* Store the new point vector */
        this.previousPoint = tempPosition.clone();
        /* Exit the loop */
        pointFound = true;
      }
    }
    /* Update the geometry */
    this.geometry.setAttribute("position", new THREE.Float32BufferAttribute(this.vertices, 3));
  }
}
}



if(isSafari == true){
    // camera.add(safariPlane)
    // exploreButton.style.opacity = 0;
    // camera.remove(controlsPlane)

    renderer.antialias = false
    renderer.autoClear = true
}

const homeButton = document.querySelector('.point4')
homeButton.hidden = true;
allobrainText.hidden=true
growthText.hidden=true
avText.hidden=true    
var OGmaterial;


    demosButton.onclick = () => {
        sound.play()
        sound12.play()
        initialDemos()
        scene.add(alloModel)
        // alloModel.getObjectByName('Icosphere002').traverse((o) => {
        //     if (o.isMesh){
        //         // o.material.depthWrite = false;
        //         // o.material.transparent = true
        //         OGmaterial = o.material
        //     }
        // })
        // console.log(OGmaterial)

    }

    const initialDemos = () => {
        audioDecider = 0;
        sound.play()
        // camera.add(controlsPlane)

        growthActivation = 0
        kaleidoShader.enabled = false
        controls.enableZoom = true;
        allobrainText.style.animation = "fadeIn4 4s"
        growthText.style.animation = "fadeIn4 4s"
        avText.style.animation = "fadeIn4 4s"
        totalScene = 0;
        backButton.hidden=true;
        bokehPass.enabled=false
        scene.remove(groupYo)
        scene.remove(brain2)
        scene.add(alloModel)
        scene.add(alloBridge)
        scene.add(ambientLight)
        scene.add(directionalLight)

    window.setTimeout(() =>
        {
            if(sound9.isPlaying == true){
            sound9.stop()
            }
            if(sound11.isPlaying==true){
                sound11.stop()
            }

            allobrainText.hidden=false
            growthText.hidden=false
            avText.hidden=false
            homeButton.hidden=false;
            homeButton.style.opacity = 1
            scene.add(sphere2)
            gsap.to(material2.uniforms.uAlpha, {value: 1, duration: 8,ease: 'power1.inOut'})

        },5000)

        window.setTimeout(() =>
        {
                                        // Canvas frame
                                        alloModel.getObjectByName('Icosphere002').traverse((o) => {
                                            if (o.isMesh){
                                                o.material= THREE.MeshStandardMaterial
                                                o.material.opacity = 0.05
                                            }
                                        })

        },8000)


        alloBridge.traverse((o) => {
            if(o.isMesh){
                gsap.to(o.material, {opacity: 0.2, duration: 6, ease: 'power1.inOut'})
            }
        })
    exploreButton.style.opacity = 0
    demosButton.style.opacity = 0
    const controlsChange = () => {
        controls.maxDistance = 9;
    }
    // console.log(alloModel.getObjectByName('Icosphere002'))
    gsap.to(camera.position, {x: 0, y: 1, z: 8, duration: 5, ease: 'power2.inOut', onComplete: controlsChange})
      // Canvas frame
      alloModel.getObjectByName('Icosphere002').traverse((o) => {
        if (o.isMesh){
            // o.material = material2
            // o.material = THREE.MeshPhysicalMaterial
            o.material.depthWrite = false;

            o.material.transparent = true
            // o.material = OGmaterial

            // best
            gsap.to(o.material, {opacity: 0.05, duration: 6, ease: 'power1.inOut'})

            // o.material.opacity = 0.05

            // o.material.opacity = 0.02
            // o.material.opacity = 0.1
            // o.material.opacity = 0.3
        }
    })
    // console.log(OGmaterial)
    // Bevel frame
    alloModel.getObjectByName('Icosphere').traverse((o) => {
        if (o.isMesh){
            // o.material = material2
            o.material.transparent = true


            // speakers switch
            // o.material.opacity = 0.1

            // o.material.map = bevelColorTexture
            // o.material.map = matcapTexture2

            // projector lighting
            // o.material.opacity = 0.06
            gsap.to(o.material, {opacity: 0.06, duration: 6, ease: 'power1.inOut'})


        }
    })
    // Bevel metal
    alloModel.getObjectByName('Icosphere003').traverse((o) => {
        if (o.isMesh){
            // o.material = material2
            o.material.transparent = true
            o.renderOrder = 9


            // speakers switch
            // o.material.opacity = 0.1

            // o.material.opacity = 1
            o.material.map = bevelColorTexture
            // o.material.map = matcapTexture2

            // projector lighting
            // o.material.opacity = 0.08
            gsap.to(o.material, {opacity: 0.08, duration: 6, ease: 'power1.inOut'})


        }
    })

}
backButton.onclick = () => {
    audioDecider = 0;
    initialDemos();
    alloModel.getObjectByName('Icosphere002').traverse((o) => {
        if (o.isMesh){
            o.material= THREE.MeshStandardMaterial
            o.material.opacity = 0.05
        }
    })

    volumeDecider2.x = 1
    const updateVolume3 = () => {
        sound9.setVolume(volumeDecider2.x)
    }
    gsap.to(volumeDecider2, {x:0, duration: 4, ease: 'power1.inOut', onUpdate: updateVolume3})

    volumeDecider.x = 0
    const updateVolume2 = () => {
        sound.setVolume(volumeDecider.x)
    }
    gsap.to(volumeDecider, {x:1, duration: 4, ease: 'power1.inOut', onUpdate: updateVolume2})

    volumeDecider3.x = 1.2
    const updateVolume4 = () => {
        sound11.setVolume(volumeDecider3.x)
    }
    gsap.to(volumeDecider3, {x:0, duration: 4, ease: 'power1.inOut', onUpdate: updateVolume4})
    sound11.play()

    if(sound10.isPlaying==true){
        sound10.stop()
    }
}



const clock = new THREE.Clock()
var kaleidoDecider =0;
var normalized;

/**
 * Animate
 */
 var last = 0;
const tick = (now) =>


{
    if (analyser) {
        // analyser.getFrequencyData() would be an array with a size of half of fftSize.
        const data = analyser.getAverageFrequency();
        normalized = data /32
        // console.log(normalized)
    }
    // console.log(avgFreq)
    if(brainObj){
    // if (vertices.length < 30000) {
    //     addPoint();
    //   }

    if (path.vertices.length < 30000) {
        if(!last || now - last >= 50) {
            last = now;
            // path.update();
        }
        path.update();


      }
    if(path.vertices.length > 1000){
        kaleidoDecider = 1
    }

    }

    // console.log(offset)
    // console.log(offsetDecider)
    // renderer.clear()
    const elapsedTime = clock.getElapsedTime()
    if(brainObj){
    groupYo.rotation.y = -elapsedTime *0.09
    groupYo.rotation.x = -elapsedTime *0.04

    }

    // Update text Intersect Yuri
    // console.log(projectorMaterial.uniforms.mouse.value)
    projectorMaterial.uniforms.progress.value = settings.progress


    // Update volumetric light
    // console.log(meshVolLookAt)
    // meshVol.lookAt(meshVolLookAt)

    // meshVol.rotation.y = elapsedTime * 0.1
    // meshVol.lookAt(cursor)
    // var angle	= 0.1 * Math.PI*2*elapsedTime
    // var target	= new THREE.Vector3(1*Math.cos(angle),0,1*Math.sin(angle))
    // meshVol.lookAt(target)
    // spotLight.target.position.copy(target)
    // console.log(cursor)

    // Update directional light
    if(directionalLightSwitch ==0){
    
    directionalLight.position.x = Math.sin(elapsedTime * 0.7)
    directionalLight.position.y = Math.cos(elapsedTime * 0.7)
    if(clickOrder ==4){
        // directionalLight.position.x = Math.sin(elapsedTime * 0.4) * 5
        // directionalLight.position.y = Math.cos(elapsedTime * 0.4) * 5
        pinPlane.position.y = (Math.sin(elapsedTime * 1.75) * 4.5) + 24
    }

    }

    // Update point light
    newPointLight.position.x = Math.sin(elapsedTime * 1.5) * 20
    newPointLight.position.z = Math.cos(elapsedTime * 1.5) * 20


    // Update shader
    // material2.uniforms.iTime.value = elapsedTime * 1.5;
    material2.uniforms.iTime.value = elapsedTime * 0.75;
    if(audioDecider ==1){
        if(normalized ==0){
            material2.uniforms.audio.value = 0.4
        }
        else if (normalized != 0 ){
    // material2.uniforms.audio.value = normalized + 0.5;
    material2.uniforms.audio.value = normalized + 0.3;
        }

    }
    if(audioDecider ==0){
        material2.uniforms.audio.value = 1
    }



    // Update controls
    controls.update()

    // Render SAFARI DECIDER
    if(isSafari == true){
        if(clickOrder != 6){
    renderer.render(scene, camera)
        }
    }
    if(isSafari == false || clickOrder ==6 || growthActivation == 1){
    effectComposer.render()
    }
 
    // Update bokeh
    // bokehPass.uniforms.maxblur.value = Math.sin(elapsedTime * 0.4) * 0.1

    // AlloModel rotation
    if(rotateDecider ==0){
    if(alloModel){
        alloModel.rotation.y = elapsedTime * 0.1
    }
    if(alloSpeakers){
        alloSpeakers.rotation.y = elapsedTime * 0.1
    }
    if(alloSpeakers2){
        alloSpeakers2.rotation.y = elapsedTime * 0.1
    }
    if(alloTruss){
        alloTruss.rotation.y = elapsedTime * 0.1
    }
    if(alloBridge){
        alloBridge.rotation.y = elapsedTime * 0.1
    }
    // terrain.rotation.z = elapsedTime * 0.1

}
// if(alloMoon){
//     alloMoon.rotation.y = elapsedTime * 0.25
// }

// location and pin look at camera
pinPlane.lookAt( camera.position )

// alloModel look at camera
// if(alloModel){
//     alloModel.lookAt(camera.position)
// }


    // Background shader
    // renderer.render(shadertoyScene, shadertoyCamera);
    // uniforms.iResolution.value.set(canvas.width, canvas.height, 1);
    // uniforms.iTime.value = elapsedTime

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

}


tick()